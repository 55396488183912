import { useFormikContext } from 'formik'
import Checkbox from 'ui/components/forms/Checkbox'
import { CheckboxesWrapper } from './styled'

export const ProjectPermUser = () => {
  const formik = useFormikContext()

  return (
    <CheckboxesWrapper>
      <Checkbox
        label="Исполнитель может назначать задачи на себя"
        id="checkbox1"
        checked={formik.getFieldProps('perm_user_self_assign').value}
        {...formik.getFieldProps('perm_user_self_assign')}
      />
      <Checkbox
        label="Исполнитель может создавать задачи"
        id="checkbox2"
        checked={formik.getFieldProps('perm_user_create_task').value}
        {...formik.getFieldProps('perm_user_create_task')}
      />
      <Checkbox
        label="Менеджер считается админом"
        id="checkbox3"
        checked={formik.getFieldProps('perm_manager_is_admin').value}
        {...formik.getFieldProps('perm_manager_is_admin')}
      />
      <Checkbox
        label="Исполнитель может перевести в готово к тестированию"
        id="checkbox4"
        checked={formik.getFieldProps('perm_user_to_rft').value}
        {...formik.getFieldProps('perm_user_to_rft')}
      />
      <Checkbox
        label="Эпик обязателен для задач"
        id="checkbox5"
        checked={formik.getFieldProps('perm_epic_required').value}
        {...formik.getFieldProps('perm_epic_required')}
      />
    </CheckboxesWrapper>
  )
}
