import styled from 'styled-components'
import { BREAKPOINTS, PALETTE } from 'styles/constants'

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  button {
    max-width: 150px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  @media (max-width: ${BREAKPOINTS.mobile}) {
    margin: 0;
  }
`

export const NotificationLink = styled.span`
  color: ${PALETTE.lightPrimary};
`

export const FieldGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;

  & > div,
  label {
    width: calc(${(p) => 100 / p.count}% - 15px);
    flex: 0 0 calc(${(p) => 100 / p.count}% - 15px);
    @media (max-width: ${BREAKPOINTS.mobile}) {
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 10px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    margin-bottom: 10px;
  }
`

export const UploaderContainer = styled.div`
  margin-bottom: 20px;
`

export const SelectWithCheckboxesWrap = styled.div`
  /* margin-bottom: 28px; */
`

export const DateGroup = styled.div`
  display: flex;
  margin-bottom: 28px;

  &:not(:last-child) {
    margin-right: 15px;
  }
`

export const DateItem = styled.div`
  &:not(:last-child) {
    margin-right: 15px;
  }

  .react-datepicker__close-icon {
    display: none;
  }
`
