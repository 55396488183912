import { useState } from 'react'
import ControlButton from 'ui/components/ControlButton'
import Select from 'ui/components/forms/Select/'
import { DeleteButtonWrap, SelectCol, SelectRow, SelectWrapper } from './styled'
import { getDifferenceArray } from '../utils'

export function SelectUser({
  users,
  userList,
  roleList,
  updateProjectUserMutation,
  deleteProjectUserMutation,
  slug,
}) {
  const [changingUser, setChangingUser] = useState(null)

  const roleChange = (values) => {
    const roles = values.map((value) => ({ id: value.id, name: value.value }))
    const deletedRoles = getDifferenceArray(changingUser.role, roles)
    const newRoles = getDifferenceArray(roles, changingUser.role)

    if (deletedRoles.length) {
      deleteProjectUserMutation({
        slug,
        userId: changingUser.id,
        roleId: deletedRoles[0].id,
      })
    }

    if (newRoles.length) {
      updateProjectUserMutation({
        slug,
        userId: changingUser.id,
        roleId: newRoles[0].id,
      })
    }

    if (
      changingUser.role.length === 1 &&
      changingUser.role[0].id === deletedRoles[0]?.id
    ) {
      setChangingUser(null)
      return
    }

    setChangingUser({ ...changingUser, role: roles })
  }

  return (
    <>
      {users?.map((user, index) => {
        return (
          <SelectRow key={user.id}>
            <SelectCol>
              <SelectWrapper>
                <Select
                  label="Имя"
                  id={`nameSelect${index}`}
                  options={userList}
                  placeholder="Имя"
                  maxHeight="200px"
                  defaultValue={{
                    ...user,
                    label: `${user.name ? `${user.surname} ${user.name}` : ''}`,
                  }}
                  isDisabled={true}
                />
              </SelectWrapper>
            </SelectCol>
            <SelectCol>
              <Select
                placeholder="Роль"
                label="Роль"
                id={`roleSelect${index}`}
                required
                options={roleList}
                onChange={(value) => roleChange([value])}
                defaultValue={user.role?.map((i) => {
                  return {
                    id: i.id,
                    value: i.label ? i.label : i.name,
                    label: i.label ? i.label : i.name,
                  }
                })}
                isDisabled={user.id !== changingUser?.id}
              />
            </SelectCol>
            {changingUser?.id === user.id && (
              <DeleteButtonWrap>
                <ControlButton
                  theme="edit"
                  onClick={() => setChangingUser(null)}
                >
                  Отмена
                </ControlButton>
              </DeleteButtonWrap>
            )}
            {changingUser?.id !== user.id && (
              <DeleteButtonWrap>
                <ControlButton
                  theme="edit"
                  onClick={() => setChangingUser(user)}
                >
                  Редактир.
                </ControlButton>
              </DeleteButtonWrap>
            )}
          </SelectRow>
        )
      })}
    </>
  )
}
