import { addDays, parse } from 'date-fns'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { uploadFile } from 'api/file'
import { checkIsFieldRequired } from 'helpers/checkIsFieldRequired'
import formatTimeBlur from 'helpers/formatTimeBlur'
import { prepareCommonSelects } from 'helpers/prepares'
import { setDateValue } from 'helpers/setDateValue'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { TASK_TYPE } from 'store/constants'
import { taskTypesSelector, prioritiesSelector } from 'store/dictionaries'
import { taskModalSelector } from 'store/modals'
import { allTasksSelector, projectInfoSelector } from 'store/projects'
import Button from 'ui/components/buttons/Button/'
import DatepickerInput from 'ui/components/forms/DatepickerInput'
import { EditorWrapper } from 'ui/components/forms/EditorWrapper/'
import FileUploader from 'ui/components/forms/FileUploader'
import Input from 'ui/components/forms/Input/'
import Select from 'ui/components/forms/Select/'
import SelectWithCheckboxes from 'ui/components/forms/SelectWithCheckboxes'
import {
  FieldGroup,
  Buttons,
  DateGroup,
  DateItem,
  UploaderContainer,
} from '../styled'

const Form = ({
  formik,
  executors,
  pending,
  visibleTaskFields,
  requiredFields,
  setFiles,
  files,
  setConfirmOpened,
}) => {
  const { isManager, isAdmin } = usePrevilegies()
  const taskList = useSelector(allTasksSelector)
  const taskTypes = useSelector(taskTypesSelector)
  const epicsList = useSelector(allTasksSelector)?.filter(
    (i) => i.task_type === TASK_TYPE.Epic
  )

  const projectInfo = useSelector(projectInfoSelector)
  const { eventType, defaultTaskDetails } = useSelector(taskModalSelector)
  const priorities = useSelector(prioritiesSelector)
  const priorityList = prepareCommonSelects(priorities)
  const preparedEpicList = prepareCommonSelects(epicsList, true)

  const preparedTaskList = prepareCommonSelects(
    taskList?.filter((i) => i.id !== defaultTaskDetails?.id),
    true
  )

  const preparedTaskListWithoutEpic = prepareCommonSelects(
    taskList?.filter((i) => i.task_type !== TASK_TYPE.Epic),
    true
  )
  const componentsList = useMemo(() => {
    return projectInfo
      ? prepareCommonSelects(projectInfo.flow.possibleProjectComponents)
      : []
  }, [projectInfo])

  const taskTypesList = useMemo(() => {
    const transformedTypesList =
      taskTypes?.length > 0
        ? prepareCommonSelects(taskTypes).filter(
            (item) => item.id !== TASK_TYPE.Backlog
          )
        : []

    return transformedTypesList
  }, [taskTypes])

  const isNotEpicTask = !(
    taskTypes.find((item) => item.name === 'Эпик')?.id ===
    formik.getFieldMeta('task_type_id').value.id
  )

  const isNewTask = eventType === 'create'

  const checkEpicIsDisabled = () => {
    if (!!isNewTask) {
      return false
    } else if (isAdmin || isManager) {
      return false
    }
    return true
  }

  const sendFileHandler = (fileList, updateFiles) => {
    const fd = new FormData()
    fileList.forEach((file) => {
      fd.append('file[]', file)
    })
    uploadFile(fd).then(({ data }) => {
      if (data) {
        setFiles([...files, ...data])
        updateFiles(data)
      }
    })
  }

  const deleteFileHandler = (fileId) => {
    setFiles(() => files.filter((file) => file.id !== fileId))
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {(visibleTaskFields?.includes('name') || isNewTask) && (
        <Input
          required={true}
          id="editNameInput"
          label="Название"
          name="name"
          meta={formik.getFieldMeta('name')}
          {...formik.getFieldProps('name')}
        />
      )}
      <FieldGroup count={3}>
        {(visibleTaskFields?.includes('task_type') || isNewTask) && (
          <Select
            id="editTypeSelect"
            required={checkIsFieldRequired('task_type', requiredFields)}
            options={taskTypesList}
            value={
              formik.getFieldMeta('task_type_id')
                ? formik.getFieldMeta('task_type_id').value
                : undefined
            }
            meta={formik.getFieldMeta('task_type_id')}
            error={
              formik.getFieldMeta('task_type_id').touched &&
              formik.getFieldMeta('task_type_id').error
            }
            onChange={(newValue) => {
              if (newValue) {
                formik.setFieldValue('task_type_id', newValue)
              } else {
                formik.setFieldValue('task_type_id', '')
              }
            }}
            label="Тип задачи"
            defaultTitle="Любой"
            maxHeight="174px"
          />
        )}
        {(visibleTaskFields?.includes('component') || isNewTask) &&
          isNotEpicTask && (
            <Select
              id="editComponentSelect"
              required={checkIsFieldRequired('component_id', requiredFields)}
              options={componentsList}
              value={
                formik.getFieldMeta('component_id')
                  ? formik.getFieldMeta('component_id').value
                  : undefined
              }
              meta={formik.getFieldMeta('component_id')}
              error={
                formik.getFieldMeta('component_id').touched &&
                formik.getFieldMeta('component_id').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('component_id', newValue)
                } else {
                  formik.setFieldValue('component_id', '')
                }
              }}
              label="Компонент"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('users') || isNewTask) &&
          isNotEpicTask && (
            <SelectWithCheckboxes
              id="editExecutorsSelect"
              // isDisabled={!canAssignWorkers}
              required={checkIsFieldRequired('executors', requiredFields)}
              options={executors}
              value={
                formik.getFieldMeta('executors')
                  ? formik.getFieldMeta('executors').value
                  : undefined
              }
              meta={formik.getFieldMeta('executors')}
              error={
                formik.getFieldMeta('executors').touched &&
                formik.getFieldMeta('executors').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('executors', newValue)
                } else {
                  formik.setFieldValue('executors', '')
                }
              }}
              label="Исполнитель"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('priority') || isNewTask) && (
          <Select
            id="editPrioritySelect"
            required={checkIsFieldRequired('priority_id', requiredFields)}
            options={priorityList}
            value={
              formik.getFieldMeta('priority_id')
                ? formik.getFieldMeta('priority_id').value
                : undefined
            }
            meta={formik.getFieldMeta('priority_id')}
            error={
              formik.getFieldMeta('priority_id').touched &&
              formik.getFieldMeta('priority_id').error
            }
            onChange={(newValue) => {
              if (newValue) {
                formik.setFieldValue('priority_id', newValue)
              } else {
                formik.setFieldValue('priority_id', '')
              }
            }}
            label="Приоритет"
            defaultTitle="Любой"
            maxHeight="174px"
          />
        )}

        {(visibleTaskFields?.includes('estimate_worker') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editEstimateWorkerInput"
              label="Оценка"
              name="estimate_worker"
              meta={formik.getFieldMeta('estimate_worker')}
              {...formik.getFieldProps('estimate_worker')}
              onBlur={(event) =>
                formatTimeBlur(event?.target?.value, formik, 'estimate_worker')
              }
            />
          )}
        {visibleTaskFields?.includes('estimate_cost') && isNotEpicTask && (
          <Input
            id="editEstimateCostInput"
            label="Оценка по смете"
            name="estimate_cost"
            meta={formik.getFieldMeta('estimate_cost')}
            {...formik.getFieldProps('estimate_cost')}
            onBlur={(event) =>
              formatTimeBlur(event?.target?.value, formik, 'estimate_cost')
            }
          />
        )}
      </FieldGroup>

      <DateGroup>
        <DateItem>
          <DatepickerInput
            id="dateStartPicker"
            autoComplete="off"
            label="Дата начала"
            isClearable
            meta={formik.getFieldMeta('date_start')}
            selected={
              formik.getFieldMeta('date_start').value
                ? parse(
                    formik.getFieldMeta('date_start').value,
                    'dd.MM.yyyy',
                    new Date()
                  )
                : undefined
            }
            onChange={(date) => setDateValue(formik, 'date_start', date)}
            instanceId="datepicker"
          />
        </DateItem>

        <DateItem>
          <DatepickerInput
            id="dateEndPicker"
            autoComplete="off"
            label="Дата завершения"
            isClearable
            minDate={
              formik.getFieldMeta('date_start').value
                ? addDays(
                    parse(
                      formik.getFieldMeta('date_start').value,
                      'dd.MM.yyyy',
                      new Date()
                    ),
                    1
                  )
                : undefined
            }
            meta={formik.getFieldMeta('date_end')}
            selected={
              formik.getFieldMeta('date_end').value
                ? parse(
                    formik.getFieldMeta('date_end').value,
                    'dd.MM.yyyy',
                    new Date()
                  )
                : undefined
            }
            onChange={(date) => setDateValue(formik, 'date_end', date)}
            instanceId="datepicker"
          />
        </DateItem>

        {(isAdmin || isManager) && (
          <DateItem>
            <DatepickerInput
              id="timePicker"
              autoComplete="off"
              label="Дедлайн"
              meta={formik.getFieldMeta('deadline')}
              isClearable
              selected={
                formik.getFieldMeta('deadline').value
                  ? parse(
                      formik.getFieldMeta('deadline').value,
                      'dd.MM.yyyy',
                      new Date()
                    )
                  : undefined
              }
              onChange={(date) => setDateValue(formik, 'deadline', date)}
              instanceId="datepicker"
            />
          </DateItem>
        )}
      </DateGroup>

      {(visibleTaskFields?.includes('description') || isNewTask) && (
        <EditorWrapper
          editorVersion={defaultTaskDetails?.editor_version || 2}
          required={checkIsFieldRequired('description', requiredFields)}
          label="Введите текст"
          name="description"
          meta={formik.getFieldMeta('description')}
          value={formik.getFieldProps('description').value}
          onChange={(value) => {
            formik.setFieldValue('description', value)
          }}
        />
      )}
      {eventType !== 'edit' && (
        <UploaderContainer>
          <FileUploader
            id="descriptionFile"
            items={files}
            smallPreview={true}
            showUploader={true}
            deleteFileHandler={deleteFileHandler}
            sendFileHandler={sendFileHandler}
          />
        </UploaderContainer>
      )}
      <FieldGroup count={4}>
        {(visibleTaskFields?.includes('block') || isNewTask) &&
          isNotEpicTask && (
            <SelectWithCheckboxes
              id="editBlockSelect"
              isClearable={true}
              options={preparedTaskList}
              value={
                formik.getFieldMeta('block')
                  ? formik.getFieldMeta('block').value
                  : undefined
              }
              meta={formik.getFieldMeta('block')}
              error={
                formik.getFieldMeta('block').touched &&
                formik.getFieldMeta('block').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('block', newValue)
                } else {
                  formik.setFieldValue('block', '')
                }
              }}
              label="Блокирует задачу"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('related') || isNewTask) &&
          isNotEpicTask && (
            <SelectWithCheckboxes
              id="editRelatedSelect"
              isClearable={true}
              options={preparedTaskListWithoutEpic}
              value={
                formik.getFieldMeta('related')
                  ? formik.getFieldMeta('related').value
                  : undefined
              }
              meta={formik.getFieldMeta('related')}
              error={
                formik.getFieldMeta('related').touched &&
                formik.getFieldMeta('related').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('related', newValue)
                } else {
                  formik.setFieldValue('related', '')
                }
              }}
              label="Связана с"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('release') || isNewTask) &&
          isNotEpicTask && (
            <Select
              id="editReleaseSelect"
              isClearable={true}
              options={preparedTaskList}
              value={
                formik.getFieldMeta('release_id')
                  ? formik.getFieldMeta('release_id').value
                  : undefined
              }
              meta={formik.getFieldMeta('release_id')}
              error={
                formik.getFieldMeta('release_id').touched &&
                formik.getFieldMeta('release_id').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('release_id', newValue)
                } else {
                  formik.setFieldValue('release_id', '')
                }
              }}
              label="В релизе"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
        {(visibleTaskFields?.includes('epic') || isNewTask) &&
          isNotEpicTask && (
            <Select
              id="editEpicSelect"
              isClearable={isAdmin || isManager || isNewTask}
              options={preparedEpicList}
              isDisabled={checkEpicIsDisabled()}
              value={
                formik.getFieldMeta('epic_id')
                  ? formik.getFieldMeta('epic_id').value
                  : undefined
              }
              meta={formik.getFieldMeta('epic_id')}
              error={
                formik.getFieldMeta('epic_id').touched &&
                formik.getFieldMeta('epic_id').error
              }
              onChange={(newValue) => {
                if (newValue) {
                  formik.setFieldValue('epic_id', newValue)
                } else {
                  formik.setFieldValue('epic_id', '')
                }
              }}
              label="Относится к эпику"
              defaultTitle="Любой"
              maxHeight="174px"
            />
          )}
      </FieldGroup>
      <FieldGroup count={3}>
        {(visibleTaskFields?.includes('layout_link') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editLayoutLinkInput"
              label="Layout Link"
              name="layout_link"
              meta={formik.getFieldMeta('layout_link')}
              {...formik.getFieldProps('layout_link')}
            />
          )}
        {(visibleTaskFields?.includes('markup_link') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editMarkupLinkInput"
              label="Markup Link"
              name="markup_link"
              meta={formik.getFieldMeta('markup_link')}
              {...formik.getFieldProps('markup_link')}
            />
          )}
        {(visibleTaskFields?.includes('dev_link') || isNewTask) &&
          isNotEpicTask && (
            <Input
              id="editDevLinkInput"
              label="Dev Link"
              name="dev_link"
              meta={formik.getFieldMeta('dev_link')}
              {...formik.getFieldProps('dev_link')}
            />
          )}
      </FieldGroup>
      {/* {(visibleTaskFields?.includes('grade_items') || isNewTask) && (
      <TaskGradeItems formik={formik} />
    )} */}
      <Buttons>
        <Button title="Сохранить" type="submit" loading={pending} />
        <Button
          title="Отменить"
          theme="borderGrey"
          type="reset"
          onClick={() => setConfirmOpened(true)}
        />
      </Buttons>
    </form>
  )
}

export default Form
